import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import logo from "../images/logo.svg"

class IndexPage extends React.Component {
  componentDidMount () {
    setTimeout(function(){ 
      document.querySelector('.main').classList.add('is-loaded'); 
    }, 1000);
  }
  render () {
    return (
      <Layout>
        <Seo title="Home" />
        <div className="page h-100 flex flex-center">
          <div className="content flex align-center m-wrap">
            <div className="logo pr30 br1 m-100 m-center m-p0 m-b0 m-mb40">
              <img src={logo} alt="Logo" width="150" />
            </div>
            <div className="content pl30 max-280 m-100 m-ma m-p0">
              Alta Design operated as a Web Development Business from 2015 - 2020. 
              For business enquiries or to touch base please <a href="mailto:carlbeaverson@gmail.com" className="link">email here</a>.
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
